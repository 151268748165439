var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-custom"},[(!_vm.isCheckoutPage && !_vm.isSingle)?_c('header',{staticClass:"modal-header pt50 center-xs px60 h4 weight-700 relative"},[_vm._v("\n    "+_vm._s(_vm.$t('Log in'))+"\n    "),_c('i',{staticClass:"pointer icon-close icon-position",attrs:{"slot":"close"},on:{"click":_vm.close},slot:"close"})]):_vm._e(),_vm._v(" "),(_vm.hasRedirect)?_c('div',{staticClass:"pt10 pb10 px65 redirect-error"},[_c('p',{staticClass:"h5 mb0 mt0"},[_vm._v("\n      "+_vm._s(_vm.$t('You need to be logged in to see this page'))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-content pt30 pb60 px60 cl-secondary",class:{'checkout-style': _vm.isCheckoutPage}},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('base-input',{staticClass:"mb10 checkout-style__input",attrs:{"type":"email","name":"email","placeholder":_vm.$t('E-mail address *'),"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: _vm.$t('Please provide valid e-mail address.')
          }
        ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('base-input',{staticClass:"mb10 checkout-style__input",attrs:{"type":"password","name":"password","placeholder":_vm.$t('Password *'),"validations":[{
          condition: !_vm.$v.password.required && _vm.$v.password.$error,
          text: _vm.$t('Field is required.')
        }]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('div',{staticClass:"checkout-style__forgot h5 cl-black align-right mb30"},[_c('a',{staticClass:"cl-black loginLink underline",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remindPassword($event)}}},[_vm._v("\n          "+_vm._s(_vm.$t('Forgot password'))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"row m0 middle-xs around-xs"},[_c('button-full',{staticClass:"col-xs-12 col-sm-5 mb10",class:{'checkout-btn': _vm.isCheckoutPage},attrs:{"type":"submit","data-testid":"loginSubmit"}},[_vm._v("\n          "+_vm._s(_vm.$t('Log in'))+" ")]),_vm._v(" "),_c('button-outline',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCheckoutPage),expression:"!isCheckoutPage"}],staticClass:"col-xs-12 col-sm-5 mb10",class:{'checkout-btn': _vm.isCheckoutPage},nativeOn:{"click":function($event){return _vm.onRegisterClick($event)}}},[_vm._v("\n          "+_vm._s(_vm.$t('Register'))+"\n        ")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }