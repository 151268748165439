<template>
  <div class="login-custom">
    <header v-if="!isCheckoutPage && !isSingle" class="modal-header pt50 center-xs px60 h4 weight-700 relative">
      {{ $t('Log in') }}
      <i slot="close" class="pointer icon-close icon-position" @click="close" />
    </header>
    <div v-if="hasRedirect" class="pt10 pb10 px65 redirect-error">
      <p class="h5 mb0 mt0">
        {{ $t('You need to be logged in to see this page') }}
      </p>
    </div>
    <div class="modal-content pt30 pb60 px60 cl-secondary" :class="{'checkout-style': isCheckoutPage}">
      <form @submit.prevent="login" novalidate>
        <base-input
          class="mb10 checkout-style__input"
          type="email"
          name="email"
          v-model="email"
          @blur="$v.email.$touch()"
          :placeholder="$t('E-mail address *')"
          :validations="[
            {
              condition: !$v.email.required && $v.email.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.email.email && $v.email.$error,
              text: $t('Please provide valid e-mail address.')
            }
          ]"
        />
        <base-input
          class="mb10 checkout-style__input"
          type="password"
          name="password"
          v-model="password"
          @blur="$v.password.$touch()"
          :placeholder="$t('Password *')"
          :validations="[{
            condition: !$v.password.required && $v.password.$error,
            text: $t('Field is required.')
          }]"
        />
        <div class="checkout-style__forgot h5 cl-black align-right mb30">
          <a class="cl-black loginLink underline" href="#" @click.prevent="remindPassword">
            {{ $t('Forgot password') }}
          </a>
        </div>
        <div class="row m0 middle-xs around-xs">
          <button-full class="col-xs-12 col-sm-5 mb10" :class="{'checkout-btn': isCheckoutPage}" type="submit" data-testid="loginSubmit">
            {{ $t('Log in') }} <!-- Log in to your account -->
          </button-full>
          <button-outline v-show="!isCheckoutPage" class="col-xs-12 col-sm-5 mb10" :class="{'checkout-btn': isCheckoutPage}" @click.native="onRegisterClick">
            {{ $t('Register') }}
          </button-outline>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Login from '@vue-storefront/core/compatibility/components/blocks/Auth/Login'

import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import ButtonOutline from 'theme/components/theme/ButtonOutline.vue'
import BaseInput from '../Form/BaseInput.vue'
import { required, email } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import CurrentPage from 'theme/mixins/currentPage'
import Vue from 'vue'

export default {
  mixins: [Login, CurrentPage],
  props: {
    isSingle: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  data () {
    return {
      hasRedirect: !!localStorage.getItem('redirect')
    }
  },
  methods: {
    onRegisterClick () {
      this.switchElem()
      this.$emit('emitSwitch', 'register')
    },
    close (e) {
      if (e) localStorage.removeItem('redirect')
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    login () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t('Please fix the validation errors'),
          action1: { label: this.$t('OK') }
        })
        return
      }
      this.callLogin()
    },
    remindPassword () {
      this.$emit('emitSwitch', 'forgot-pass')
      if (!(typeof navigator !== 'undefined' && navigator.onLine)) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t('Reset password feature does not work while offline!'),
          action1: { label: this.$t('OK') }
        })
      } else {
        this.callForgotPassword()
      }
    },
    onSuccess () {
      let currentUser = this.$store.state.user.current
      let userAddress = currentUser && currentUser.addresses.length
        ? currentUser.addresses[0]
        : ''
      Vue.gtm.trackEvent({
        event: 'usersigninform',
        eventCategory: 'user',
        eventAction: 'signin',
        'user-email': this.email,
        'user-name': currentUser ? currentUser.firstname : '',
        'user-surname': currentUser ? currentUser.lastname : '',
        'user-city': userAddress && userAddress.city,
        'user-country': userAddress && userAddress.country_id
      })
      this.$store.dispatch('wishlist/load', true)
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: this.$t('You are logged in!'),
        action1: { label: this.$t('OK') }
      })
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      })
    }
  },
  components: {
    ButtonFull,
    ButtonOutline,
    BaseInput
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-error: color(error);
$white: color(white);
$main: color(main);

.checkout-btn {
  width: 100% !important;
  max-width: none !important;
}
.checkout-style {
  @media (max-width: 767px) {
    padding: 10px 0 0 0;
    &__forgot {
      margin-bottom: 10px;
    }
  }
}
.modal-header {
  @media (max-width: 767px) {
    padding: 30px 0 0 0;
    font-size: 20px;
  }
}
.icon-position {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #646464;
  @media (max-width: 767px) {
    right: 15px;
    top: 15px;
  }
}
.register-btn {
  padding: 13px 40px;
  border: 2px solid #000;
  border-radius: 50px;
  margin-right: 20px;
  text-align: center;
  @media (max-width: 400px) {
    padding: 8px 0px;
    width: 130px;
    max-width: 130px;
    margin-right: 0px;
  }
}
.login-btn {
  font-weight: 400;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 400px) {
    padding: 10px 0px;
    width: 130px;
    max-width: 130px;
  }
}
.loginLink {
  @media (max-width: 767px) {
    color: $main;
  }
  &:hover {
    color: $main;
    text-decoration: underline;
  }
}
.modal-content {
  @media (max-width: 400px) {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
}
.redirect-error {
  background-color: $color-error;
  color: $white;
}
</style>
