<template>
  <div class="forgotpass-custom">
    <header v-if="!isSingle" class="modal-header pt50 center-xs px60 h4 weight-700 relative">
      {{ $t('Reset password') }}
      <i v-show="!isCheckoutPage" slot="close" class="pointer icon-close icon-position" @click="close" />
    </header>

    <div class="modal-content pb30 px60 cl-black">
      <template v-if="!passwordSent">
        <form @submit.prevent="sendEmail" novalidate>
          <div class="mb25 mt30">
            <p class="mt0 mb15 info-text">
              {{ $t('Enter your email to receive instructions on how to reset your password.') }}
            </p>
            <base-input
              type="email"
              name="email"
              v-model="email"
              focus
              :placeholder="$t('E-mail address *')"
              :validations="[
                {
                  condition: !$v.email.required && $v.email.$error,
                  text: $t('Field is required.')
                },
                {
                  condition: !$v.email.email && $v.email.$error,
                  text: $t('Please provide valid e-mail address.')
                }
              ]"
            />
          </div>
          <button-full :disabled="$v.$invalid" class="mb20 w-100" type="submit">
            {{ $t('Reset password') }}
          </button-full>
          <div class="center-xs">
            {{ $t('or') }}
            <a href="#" class="loginLink cl-black" @click.prevent="switchElem">
              {{ $t('return to log in') }}
            </a>
          </div>
        </form>
      </template>
      <template v-if="passwordSent">
        <form class="py20 center-xs">
          <p class="py30 mb80">
            {{ $t("We've sent password reset instructions to your email. Check your inbox and follow the link.") }}
          </p>
          <button-full class="reset-btn w-100 fs16 bg-cl-black" type="link" @click.native="switchElem">
            {{ $t('Back to login') }}
          </button-full>
        </form>
      </template>
    </div>
  </div>
</template>

<script>

import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseInput from '../Form/BaseInput.vue'
import { required, email } from 'vuelidate/lib/validators'
import { Logger } from '@vue-storefront/core/lib/logger'
import i18n from '@vue-storefront/i18n'
import CurrentPage from 'theme/mixins/currentPage'

export default {
  props: {
    isSingle: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    close () {
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    sendEmail () {
      // todo: send email with reset password instructions

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Please fix the validation errors'),
          action1: { label: i18n.t('OK') }
        })
        return
      }

      this.$bus.$emit('notification-progress-start', i18n.t('Resetting the password ... '))
      this.$store.dispatch('user/resetPassword', { email: this.email }).then((response) => {
        this.$bus.$emit('notification-progress-stop')
        if (response.code === 200) {
          this.passwordSent = true
        } else {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: i18n.t(response.result) || i18n.t('Error while sending reset password e-mail'),
            action1: { label: i18n.t('OK'), action: 'close' }
          })
        }
      }).catch((err) => {
        Logger.error(err)()
        this.$bus.$emit('notification-progress-stop')
      })
    },
    switchElem () {
      this.$emit('emitSwitch', 'login')
      this.$store.commit('ui/setAuthElem', 'login')
    }
  },
  name: 'ForgotPass',
  mixins: [CurrentPage],
  data () {
    return {
      email: '',
      passwordSent: false
    }
  },
  components: {
    ButtonFull,
    BaseInput
  }
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);
$light-gray: color(light-gray);

.modal-header {
  @media (max-width: 767px) {
    padding: 30px 0 0 0;
    font-size: 20px;
  }
}
.icon-position {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #646464;
  @media (max-width: 767px) {
    right: 15px;
    top: 15px;
  }
}
.info-text {
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.loginLink {
  &:hover {
    color: $main;
    text-decoration: underline;
  }
}
.modal-content {
  @media (max-width: 400px) {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
}
.reset-btn {
  font-weight: 400;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 400px) {
    padding: 10px 0px;
  }
}
</style>
